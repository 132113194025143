.overlay_image{
  width: 100vw !important;
  left: 0 !important;
  height: 768px;
  display: none !important;
  @include break_point(bp_tablet_portrait_up){
    display: block !important;
    opacity: 0.7;
  }
}

.slide_text {
  @include break_point(just_smartphone_portrait){
    left: 0px !important;
    top: 120px !important;
    width: 100% !important;
    background: $color_main; /* For browsers that do not support gradients */
    background: radial-gradient(rgba(115, 121, 124, 0.5) 60%, rgba(115, 121, 124, 0.2) 30%); /* Standard syntax */
    span{      
      white-space: normal; 
      margin: 0 auto !important;
      font-size: 1.5rem !important;   
      line-height: 1.5rem !important; 
      max-width: 85% !important;
      display: block;        
    }
  } 
  @include break_point(just_smartphone_landscape){
    left: 0px !important;
    top: 130px !important;
    width: 100% !important;
    min-height: 2rem;
    background: $color_main; /* For browsers that do not support gradients */
    background: radial-gradient(rgba(115, 121, 124, 0.5) 60%, rgba(115, 121, 124, 0.2) 30%); /* Standard syntax */
    span{
      margin: 0 auto !important;
      white-space: normal;
      font-size: 1.6rem !important;
      line-height: 1.5rem !important; 
      max-width: 80% !important;
      display: block;
    }
  }
  @include break_point(just_tablet_portrait){
    left: 0px !important;
    top: 190px !important;
    width: 100% !important;
    min-height: 2rem;
    background: $color_main; /* For browsers that do not support gradients */
    background: radial-gradient(rgba(115, 121, 124, 0.5) 60%, rgba(115, 121, 124, 0.2) 30%); /* Standard syntax */
    span{
      margin: 0 auto !important;
      font-size: 2rem !important;
      letter-spacing: 1px !important;
      white-space: normal !important;  
      line-height: 2.5rem !important; 
      max-width: 80% !important;
      display: block;
    }
  }    
  @include break_point(just_tablet_landscape){
    left: 0px !important;
    width: 100vw !important;
    span{
      margin: 0 auto !important;
      font-size: 1.5rem !important;
      letter-spacing: 1px !important;
      white-space: nowrap !important;  
      line-height: 1.5rem !important; 
      max-width: 70% !important;
      display: block;
    }
  } 
  @include break_point(bp_tablet_big_landscape_up){
    span{
      font-size: 2rem !important;   
      line-height: 2rem !important; 
      letter-spacing: 0 !important;
      max-width: 85% !important;
      display: block;        
    }
  }
}//.slide_text

.cs-navigation{
  display: none !important;
}