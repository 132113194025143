.swiper-container {
  width: 100%;
}

.swiper-pagination-bullet-active{
  background: #fff !important;
}


.project_box{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  
  .project_image{
    display: block;
  }
  .project_image_overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; 
    background: linear-gradient(145deg, rgba(0,0,0,.3), rgba(0,0,0,0));

    .project_image_caption{
      color: white;
      font-weight: bold;
      position: absolute;
      top: 1.5em;
      left: 1.3em;
    }
  }
}



