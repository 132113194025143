.contact_box{
  display: grid;
  grid-template-columns: 1fr 3fr; 
  grid-gap: 5px;
  @include break_point(bp_tablet_landscape_up){
    grid-template-columns: 1fr 5fr;      
  }
  @include break_point(bp_tablet_big_landscape_up){
    grid-template-columns: 1fr 5fr; 
  }
  @include break_point(bp_desktop_hi_res_up){
    grid-template-columns: 1fr 6fr;
  } 
  p{
    margin: 0;
  }
}


.companies_contact_list{
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  h4{
    display: inline-block;
  }
  p{
    margin: 0;
  }
  
  
  .company_info{
    display: grid;
    align-content: center;
    align-items: center; 
    grid-template-columns: 1fr;
    grid-gap: 10px;
    @include break_point(bp_tablet_portrait_up){
      grid-template-columns: 1fr 2fr 3fr;      
    }
    @include break_point(bp_tablet_landscape_up){
      grid-template-columns: 1fr 2fr 3fr;      
    }
  }
  
  .company_address{
    display: flex;
    flex-direction: column;
  }
}

.companies_contact_list > .company_contact:first-of-type{
  hr{
    display: none;
  }
}
.group_info{
  display: grid;   
  grid-template-columns: 1fr;
  @include break_point(bp_tablet_landscape_up){
    grid-template-columns: 1fr 3fr;      
  }
}

.company_contact_us{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  @include break_point(bp_tablet_landscape_up){
    grid-template-columns: 1fr 1fr;      
  }
  @include break_point(bp_tablet_big_landscape_up){
    grid-template-columns: 1fr 1fr; 
  }
  @include break_point(bp_desktop_hi_res_up){
    grid-template-columns: 1fr 1fr;
  } 
}

