body{
  background-color: $color_body;
  margin: 0px;
  padding: 0px;
  font-family: $font_logo;
  color: $color_main;
  line-height: 1.5;
  font-size: 0.8em;
  @include break_point(bp_tablet_landscape_up){
    font-size: 0.8em;
  }
  @include break_point(bp_tablet_big_landscape_up){
    font-size: 0.9em;
  }
  @include break_point(bp_desktop_hi_res_up){
    font-size: 1em;
  }
  
}

#wrapper{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}  

#container{
  flex: 1 0 auto;
}

.page-container{
  margin: 0 auto;
  -ms-flex-positive: 0 !important; // fallback for IE 11
  flex-grow: 0 !important; // fallback for IE 11
  a{
    text-decoration: none;
    color:$color_main_darker;
  }
  a:hover{
    text-decoration: underline;
  }
  @include break_point(bp_tablet_portrait_up){
    margin-top: 20px;
    width: 100%;
    .main_content,
    .entry-title,
    .company_logo{
      padding: 0 30px;
    }
  }
  @include break_point(bp_tablet_landscape_up){
    margin-top: 20px;
    width: 90%;
  }
  @include break_point(bp_tablet_big_landscape_up){
    margin-top: 25px;
    width: 85%;
  }
  @include break_point(bp_desktop_hi_res_up){
    margin-top: 30px;
    width: 80%;
  }
}

img{
  max-width: 100%;
  height: auto;
}

.entry-content{
  h3{
    font-size: 1.3em;
  }
}

.post-content{
  margin: 0 5px;
  @include break_point(bp_tablet_portrait_up){
    margin: 0;
  }
}

.page-header{
  margin: 0 5px;
}

.entry-content{
  margin: 0 5px;
  @include break_point(bp_tablet_portrait_up){
    margin: 0;
    padding: 0 35px;
  }
}

.entry_company_content,
.entry_service_content{
  display: flex; // fallback for IE 11
  flex-wrap: nowrap; // fallback for IE 11
  .main_content{// fallback for IE 11
    max-width: 50%;
  }
  .main_slider{// fallback for IE 11
    max-width: 50%;
  }

  @supports (grid-template-columns: 1fr){
    display: grid;
    grid-template-columns: minmax(97vw, 100%);
    grid-gap: 20px;  
    .main_content{
      max-width: 100%;
    }
    .main_slider{
      max-width: 100%;
    }
  }
  
  @include break_point(bp_tablet_landscape_up){
    grid-template-columns: minmax(400px, 50%) minmax(400px, 50%);      
  }
  @include break_point(bp_tablet_big_landscape_up){
    grid-template-columns: minmax(400px, 50%) minmax(400px, 50%); 
  }
  @include break_point(bp_desktop_hi_res_up){
    grid-template-columns: minmax(400px, 50%) minmax(400px, 50%);
  }  
  p:first-child {
    margin-top: 0;
  }    
}

#content{
  margin: 0 5px;
  @include break_point(bp_tablet_portrait_up){
    margin: 0;
  }
}

.place_holders{
  width: 100%;
}

.entry_summary{
  h4{
    font-size: 1rem;
  }
}

.entry-content-page{
  margin: 0 30px;
}

.company_post_logo{
  padding: 0 30px;
  max-height: 5rem;
}