$color_main: #73797c;
$color_main_font: #DDE1DF;
$color_main_darker: #353739;
$color_body: #fff;
$color_GUNMETAL: #2B2D42;
$color_light_blue: #0093d0;


@import url('https://fonts.googleapis.com/css?family=Lato:400,900');
$font_logo: 'Lato', sans-serif;
$font_logo_bold: 900;