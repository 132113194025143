.search_form{
  position: relative;
}
.search_icon{
  position: absolute;
  top: 7px;
  left: 10px;
}

.search_field{
  width: 250px;
  height: 32px;
  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;  
  text-indent: 32px;
  border-right: none;
  padding-right: none;
}

.search_field:focus{
  &::-webkit-input-placeholder {
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
   }

   &:-moz-placeholder { /* Firefox 18- */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
   }

   &::-moz-placeholder {  /* Firefox 19+ */
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
   }

   &:-ms-input-placeholder {  
      color: transparent;
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
   }
}

.search_submit{
  height: 32px;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;  
  vertical-align: bottom;
  margin-left: -2px;
}