header{
  display: flex;
  justify-content: space-around;
  align-items: center;  
  margin-bottom: 0.6em;
  margin-top: 0.6em;
}//header

#branding{
  display: flex;
  align-items: center;
  
  .site-logo{
    max-width: 12em;
  }
}//#branding

#site-description{
  display: none;
  margin:0;
  padding-left: 1.5em;
  font-size: 1.4em;
  color: $color_main;
  font-weight: $font_logo_bold;
  text-transform: uppercase;
  align-self: flex-end;
  @include break_point(bp_desktop_hi_res_up){
    display: block;
  }
  
}//#site-description

.big-menu{
  display: none;  
  
  @include break_point(bp_tablet_landscape_up){
    display: block;
    padding-top: 10px;    
  }//@include break_point(bp_tablet_portrait)
  
}//.big menu