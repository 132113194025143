/*
Theme Name: Kajima USA Light Theme
Author: Saeid Ostad
Author URI: https://www.linkedin.com/in/saeidostad
Description: Design and Development for Kajima USA by Saeid Ostad
Version: 1.0
Tags: white, light, flexible-width, custom-header, custom-menu, featured-images, microformats, post-formats
*/

/*
Normilizer
-------------------------------------------*/
@import "normalize";

/*
Variables
-------------------------------------------*/
@import "variables";

/*
Mixins
-------------------------------------------*/
@import "mixins";

/*
Front page
-------------------------------------------*/
@import "front-page";

/*
General
-------------------------------------------*/
@import "general";

/*
Header
-------------------------------------------*/
@import "header";

/*
Footer
-------------------------------------------*/
@import "footer";

/*
Slider
-------------------------------------------*/
@import "slider";

/*
Wordpress Image Alignment
-------------------------------------------*/
@import "wp-image-align";

/*
Custome Elements
-------------------------------------------*/
@import "elements";
