@mixin break_point($point) {
    
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  $bp_smartphone_portrait_up: (min-width:320px); 
  
  /* 0px until smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  $just_smartphone_portrait: "(max-width:320px)" ; 
   
  /* smartphones, Android phones, landscape iPhone */
  $bp_smartphone_landscape_up: (min-width:480px);
   
  /* just smartphones, Android phones, landscape iPhone */
  $just_smartphone_landscape: "(min-width:321px) and (max-width:480px)" ;
  
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  $bp_tablet_portrait_up: (min-width:600px);
  
  /* just portrait tablets */
  $just_tablet_portrait: "(min-width:481px) and (max-width:600px)" ;
  
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  $bp_tablet_landscape_up: (min-width:800px);
    
  /* just tablet, landscape iPad, lo-res laptops ands desktops */
  $just_tablet_landscape: "(min-width:601px) and (max-width:800px)" ;
  
  /* big landscape tablets, laptops, and desktops */
  $bp_tablet_big_landscape_up: (min-width:1024px);
      
  /* just big landscape tablets, laptops, and desktops */
  $just_tablet_big_landscape: "(min-width:801px) and (max-width:1024px)" ;
  
  /* hi-res laptops and desktops */ 
  $bp_desktop_hi_res_up: (min-width:1281px);

  @if $point == bp_smartphone_portrait_up {
    @media #{$bp_smartphone_portrait_up} { @content; }
  }
  @else if $point == just_smartphone_portrait {
    @media #{$just_smartphone_portrait}  { @content; }
  }
  @else if $point == bp_smartphone_landscape_up {
    @media #{$bp_smartphone_landscape_up} { @content; }
  }
  @else if $point == just_smartphone_landscape {
    @media #{$just_smartphone_landscape}  { @content; }
  }
  @else if $point == bp_tablet_portrait_up {
    @media #{$bp_tablet_portrait_up}  { @content; }
  }
  @else if $point == just_tablet_portrait {
    @media #{$just_tablet_portrait}  { @content; }
  }
  @else if $point == bp_tablet_landscape_up {
    @media #{$bp_tablet_landscape_up}  { @content; }
  }
  @else if $point == just_tablet_landscape {
    @media #{$just_tablet_landscape}  { @content; }
  }
  @else if $point == bp_tablet_big_landscape_up {
    @media #{$bp_tablet_big_landscape_up}  { @content; }
  }
  @else if $point == just_tablet_big_landscape {
    @media #{$just_tablet_big_landscape}  { @content; }
  }
  @else if $point == bp_desktop_hi_res_up {
    @media #{$bp_desktop_hi_res_up}  { @content; }
  }
}