footer{
  background: $color_body;
  color: $color_main-darker;
  font-size: 1em;
  padding: 0.9em 0;
  .footer-wrapper{
    margin: 0 auto;
    @include break_point(bp_tablet_landscape_up){
      width: 90%;
      font-size: 0.9em;
    }
    @include break_point(bp_tablet_big_landscape_up){
      width: 95%;
    }
  }
  a{
    text-decoration: none;
    color:$color_main_darker;
  }
  a:hover{
    color:$color_main;
  }
  
  ul{
    list-style: none;
    padding-left: 0em;
  }
  
}


.footer-columns{
  margin: 0 auto;
  @include break_point(bp_tablet_landscape_up){
    display: flex;
    justify-content: space-around;
    width: 95%;
  }
  @include break_point(bp_desktop_hi_res_up){
    width: 90%;
  }
  @include break_point(just_tablet_big_landscape){
    flex-direction: column-reverse;      
  }
  
  .footer-column{
    margin: 0 auto;
    width: 90%;
    padding: 10px;    
  }      
}

.copyright{
  text-align: center;
  color: $color_main;
}
