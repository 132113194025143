.companies_card {
  a:hover {
    text-decoration: none;
  }
  display: flex; // fallback for IE 11
  flex-wrap: wrap; // fallback for IE 11
  display: grid;
  @include break_point(bp_tablet_portrait_up) {
    grid-template-columns: 1fr 1fr;
  }
  @include break_point(bp_tablet_landscape_up) {
    grid-template-columns: 1fr 1fr;
  }
  @include break_point(bp_tablet_big_landscape_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include break_point(bp_desktop_hi_res_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
} //.companies_card

.company_card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: block;
  justify-content: flex-start;
  flex-direction: column;
  margin: 2em;
  position: relative;
  @include break_point(bp_tablet_portrait_up) {
    width: 50%; // fallback to support IE 11
  }
  @include break_point(bp_tablet_landscape_up) {
    width: 30%; // fallback to support IE 11
  }
  @include break_point(bp_tablet_big_landscape_up) {
    width: 25%; // fallback to support IE 11
  }
  @include break_point(bp_desktop_hi_res_up) {
    width: 25%; // fallback to support IE 11
  }

  @supports (grid-template-columns: 1fr) {
    display: flex;
    @include break_point(bp_tablet_portrait_up) {
      width: 85%;
    }
    @include break_point(bp_tablet_landscape_up) {
      width: 85%;
    }
    @include break_point(bp_tablet_big_landscape_up) {
      width: 85%;
    }
    @include break_point(bp_desktop_hi_res_up) {
      width: 85%;
    }
  }
}

/* On mouse-over, add a deeper shadow */
.company_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.leadership_cards {
  a:hover {
    text-decoration: none;
  }
  display: flex; // fallback to support IE 11
  flex-wrap: wrap; // fallback to support IE 11
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  @include break_point(bp_tablet_portrait_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include break_point(bp_tablet_landscape_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include break_point(bp_tablet_big_landscape_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include break_point(bp_desktop_hi_res_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .leadership_card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 1.5;
    margin: 0.8rem 1.5rem;
    position: relative;
    .leadership_card_image {
      width: 100%;
      height: 100%;
    }
    @include break_point(bp_tablet_portrait_up) {
      width: 50%; // fallback to support IE 11
    }
    @include break_point(bp_tablet_landscape_up) {
      width: 25%; // fallback to support IE 11
    }
    @include break_point(bp_tablet_big_landscape_up) {
      width: 25%; // fallback to support IE 11
    }
    @include break_point(bp_desktop_hi_res_up) {
      width: 25%; // fallback to support IE 11
    }

    @supports (grid-template-columns: 1fr) {
      @include break_point(bp_tablet_portrait_up) {
        width: 85%;
      }
      @include break_point(bp_tablet_landscape_up) {
        width: 85%;
      }
      @include break_point(bp_tablet_big_landscape_up) {
        width: 85%;
      }
      @include break_point(bp_desktop_hi_res_up) {
        width: 85%;
      }
    }
  }
} //.leadership_cards

/* On mouse-over, add a deeper shadow */
.leadership_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.service_company_cards {
  display: flex; // fallback to support IE 11
  flex-wrap: wrap; // fallback to support IE 11
  display: grid;
  font-size: 0.8rem;
  h4 {
    font-size: 0.9rem;
  }
  h3 {
    font-size: 1rem;
  }
  a:hover {
    text-decoration: none;
  }
  @include break_point(bp_tablet_portrait_up) {
    grid-template-columns: 1fr 1fr;
  }
  @include break_point(bp_tablet_landscape_up) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include break_point(bp_tablet_big_landscape_up) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @include break_point(bp_desktop_hi_res_up) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.service_item {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 2em;
  position: relative;
  padding-left: 1em;
  h3 {
    display: inline-block;
  }
}

/* On mouse-over, add a deeper shadow */
.service_item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.text_container {
  padding: 2px 16px;
}

.logobg {
  position: absolute;
  height: 20%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 1;
}
.logobginner {
  padding: 5px 15px 15px 5px;
  border-radius: 0 0 40px 0;
  background: #fff;
}

.company_logo {
  width: 9em;
  padding-bottom: 0;
  @include break_point(bp_tablet_landscape_up) {
    width: 7em;
    padding-bottom: 1em;
  }
}

@supports not (grid-template-columns: 1fr) {
  .entry_company_content,
  .entry_service_content {
    display: flex;
    .main_content p {
      padding-right: 10px;
    }
    .main_content,
    .main_slider {
      width: 50%;
    }
  } //.entry_company_content,

  .service_company_cards {
    display: flex;
    flex-wrap: wrap;

    @include break_point(bp_tablet_landscape_up) {
      .company_card {
        width: 33.3333%;
      }
    }
    @include break_point(bp_tablet_big_landscape_up) {
      .company_card {
        width: 25%;
      }
    }
    @include break_point(bp_desktop_hi_res_up) {
      .company_card {
        width: 20%;
      }
    }
  } //.service_company_cards

  .companies_card {
    display: flex;
    flex-wrap: wrap;

    @include break_point(bp_tablet_landscape_up) {
      .company_card {
        width: 33.3333%;
      }
    }
    @include break_point(bp_tablet_big_landscape_up) {
      .company_card {
        width: 33.3333%;
      }
    }
    @include break_point(bp_desktop_hi_res_up) {
      .company_card {
        width: 27.3333%;
      }
    }
  } //.companies_card

  .leadership_cards {
    display: flex;
    flex-wrap: wrap;

    @include break_point(bp_tablet_landscape_up) {
      .leadership_card {
        width: 25%;
      }
    }
    @include break_point(bp_tablet_big_landscape_up) {
      .leadership_card {
        width: 25%;
      }
    }
    @include break_point(bp_desktop_hi_res_up) {
      .leadership_card {
        width: 20%;
      }
    }
  } // .leadership_cards

  .company_info {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    .company_logo {
      width: 10%;
    }
    .company_contact_header {
      width: 30%;
      margin-left: 1rem;
    }
    .company_address {
      width: auto;
    }
    .group_info {
      display: flex;
    }
  } //.company_info
} //end of @support
