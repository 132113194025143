/*
Cards Styles
-------------------------------------------*/
@import 'card';

/*
Custome Elements
-------------------------------------------*/
@import 'search';

/*
Custome Elements
-------------------------------------------*/
@import 'contact';

/*
Swiper Styles
-------------------------------------------*/
@import 'swiper';

/*
Company Google Map Styles
-------------------------------------------*/
@import 'google-map';

//hide timeline header
.timeline-main-title{
  display: none !important;
}
